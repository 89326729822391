import React from 'react'
import Link from 'gatsby-link'
import Layout from '../layouts';

const IndexPage = () => (
  <Layout>
    <div>
      <h1 className="page-title">Should you move to Sibley, Iowa?</h1>
      <div className="row">
        <div className="col-md-6 col-md-offset-3">
          <div className="yes">I think it's a good place to live.</div>
          <p>
            But, like any town, it has its problems. I'll give you my
            <em> opinion</em> on both the good and the bad so that you can make an
            informed decision.
          </p>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-md-10 col-md-offset-1">
          <div className="section">
            <p className="lead">
              I've lived in Sibley for nearly my entire life, excluding a brief
              period where I lived in the town of Ocheyedan, 12 miles to the east.
              I've been a child here, a student here, and now a homeowner.
            </p>
            <p className="lead">
              When I created this website it may have surprised many readers to
              have learned that, contrary to what some of them have implied (🙄),
              I really do enjoy living here. Among my friends from school - now
              over a decade ago - I was one of only three to stay here after
              finishing college.
            </p>
            <p className="lead">
              Sometimes I honestly wish that my spouse and I had decided to move
              to a bigger city like Sioux Falls, South Dakota, or even a bigger
              town like Sheldon, Iowa to the south, or Worthington, Minnesota to
              the north. But I'd be lying if I said there weren't times we're glad
              we stayed in Sibley, too.
            </p>
            <p className="lead">
              Throughout the rest of this page, I'm going to do my best to explain
              that Sibley has a lot of good things going for it. However, in my
              own opinion, it also has its share of shortcomings. It's up to you
              as the reader to weigh the good against the bad.
            </p>
            <p className="lead">
              One of those shortcomings <strong>{" was "}</strong> a
              business that many of us in town had taken to calling{' '}
              <strong>the "Blood Plant"</strong>. It was an agricultural processing
              plant located smack dab in the heart of downtown Sibley,{' '}
              <em>within spitting distance of the only grocery store in town</em>.
              This "Blood Plant" got its name from a particular type of processing
              they did there; they'd take pig blood, dry it, and turn it into pet
              food.
            </p>
            <p className="lead">
              Needless to say, the scent from drying tons of pig blood isn't going
              to smell like roses and sunshine. The "Blood Plant" used to emit
              downright noxious fumes and odors every day -- odors that you could
              smell no matter where you live in Sibley. It was like walking into a
              wall of the worst, most pungent and offensive smells you could imagine.
            </p>
            <p className="lead">
              That business, its emissions, and our local government's dealings
              with them were the reasons I built this website to begin with. I'm
              happy to report that <strong>{" the Blood Plant has shut down and a new business has moved in."}</strong>
              {' '}Where before you could walk into a miasma of
              noxious vapors four to five days <em>every week</em>, it's happily no longer a problem in Sibley and the residents can breathe easily.
              {/* While the City council would have you believe emissions from the "Blood Plant" 
              have been completely reduced to zero, I <strong>strongly disagree</strong>, 
              and we're still tracking the situation on this website.{' '} */}
              <Link to="/blood-plant">
              {' '}You can read more about the history of the plant, the town, and
                this website at this link.
              </Link>
            </p>
            <blockquote>
              It should go without saying that everything you just read, and
              everything you're about to read, is purely opinion. Only a fool
              would take it as fact.
            </blockquote>
            <h3 className="section-header">What Sibley has going for it.</h3>
            <p className="lead">
              Despite my{' '}
              <Link to="/free-speech">legal battle with the City of Sibley</Link>,
              I'm doing my best to be fair to the town itself, because{' '}
              <em>I do like living here</em>. If you think something does or does
              not belong on this list, please don't hesitate to contact me at
              <a href="mailto:joshua@nozzlegear.com"> joshua@nozzlegear.com</a>.
              I'd be happy to discuss it with you.
            </p>
            <ol>
              <li>
                As an individual lucky enough to be in a profession where I can
                work wherever I want, The Lantern coffeehouse is easily one of my
                favorite places to be. I'm not even much of a coffee-drinker, but
                the vibe is just right and the owners are very friendly. It's my
                understanding that it was even rated as one of the best coffee
                houses in Iowa.
              </li>
              <li>
                The Osceola Recreational Trails Association has done a very good
                job of not only adding new hiking trails all around the town, but
                also updating old gravel and dirt trails with freshly-poured
                concrete. They're a great way for the population to stay healthy
                and get exercise for both themselves and their pets.
              </li>
              <li>
                El Tio Nacho's was not only one of my favorite Mexican restaurants,
                it was one of my favorite restaurants ever. The food was less "5-star
                Michelin Chef" and more like home-cooked Mexican, and I can't
                think of a single time I had regretted eating at El Tio's instead
                of somewhere more fancy.
                <ul>
                  <li>
                    <strong>
                      Unfortunately, the owners have sold El Tio Nacho's and are now enjoying retirement. A new restaurant named El Quaretano has opened in its place. The food is good and I recommend giving it a try!
                    </strong>
                  </li>
                </ul>
              </li>
              <li>
                Sibley recently got "fiber" internet capabilities through
                Premier Communications. As a professional that makes their living
                online, access to fiber internet was something I thought I'd only
                get in bigger cities. The upload speeds are a little slower than I'd prefer
                at around 12mbps, but the download speeds of 150mpbs and greater 
                have been extremely reliable. 
              </li>
              <li>
                In 2019, the community completed fundraising for a new community pool.
                The old pool was in desperate need of repair, and after a lengthy summer
                of construction, the new pool is now open to the community. 
              </li>
              <li>
                The Sibley-Ocheyedan School District was{' '}
                <a href="http://www.nwestiowa.com/news/sound-the-trumpets/article_f490e8fa-4a5d-11e8-8c17-d3b43564f9ba.html">
                  {' '}
                  named one of the "Best Communities for Music Education" in 2018,
                </a>{' '}
                an award shared with only 583 other communities in the country.
              </li>
            </ol>
            <h3 className="section-header">What do Sibley residents complain about?</h3>
            <p className="lead">
              Some of these complaints are petty, and some of them are much more
              serious. However, this website has an audience, I know the city
              council is reading, and I want to do what I can to improve the town
              by bringing attention to some of the problems that exist here.
            </p>
            <p className="lead">
              Again, if you think something does or does not belong on this list,
              please don't hesitate to contact me at
              <a href="mailto:joshua@nozzlegear.com"> joshua@nozzlegear.com</a>.
            </p>
            <ol>
              <li>
                Many of the roads in town, especially the cobblestone roads
                located "downtown" on main street are in
                <em> very</em> poor condition. It makes the town look rough and run-down,
                and leaves a bad impression not only on the mind but
                possibly the vehicle as well. I'm no civil engineer, but there has
                to be a better method of road maintenance than the city's current
                strategy of "dig a huge chunk out of the road and just fill it
                with dirt for months at a time, inevitably creating potholes
                everywhere". I might be wrong, that might be how it should be done (I haven't the foggiest idea) but at the very least it can be annoying.
              </li>
              <li>
                While the odors coming out of the "Blood Plant" {' '}
                <strong>are no longer an issue</strong>, 
                a new business has taken up residence in the plant and we residents are wary that one day we'll wake up to find the new business producing odors just like the old one.
              </li>
              <li>
                <a
                  href="https://www.youtube.com/watch?v=Dy1Xq9a1qOI"
                  title="DON'T @ ME"
                >
                  There are too many damned Republicans.
                </a>{' '}
                😉
              </li>
              <li>
                Despite all of the new trails put up by the trail association,
                Sibley could
                <em> really</em> use one of the dog parks that many other towns
                take for granted. Like many other millenials, when my spouse and I
                are ready to buy our next house we'll be looking very closely at
                towns that have dog parks.
              </li>
              <li>
                Sibley is pretty poor in the "places to eat" department. You've
                got five choices: Subway, Pizza Hut, Pizza Ranch,
                reBar Lounge & Lanes (which, as a burger lover and former meat-eater, has great burgers), 
                1015 Steak Company, and El Quaretano. As much as I <em> love</em> pizza and Mexican food, it's easy to get bored of these places when
                you've eaten at each of them dozens of times. If you live in
                Sibley, you're either eating at one of those establishments,
                traveling 15-20 miles to Worthington, Minnesota or Sheldon, Iowa,
                or you're making your own meal.
              </li>
              <li>
                Some officials in the city government,
                <em> in my own opinion</em> (insert additional legal caveats here
                ad nauseum to really drive home the point that nothing on this
                page should be taken as fact), squander taxpayer money on spurious
                advice and pointless legal services,
                <a href="https://www.aclu-ia.org/sites/default/files/2-1_ex._a.pdf">
                  {' '}
                  threatening civil litigation{' '}
                </a>
                that even a first-year law student would know has no possibility
                of succeeding. Those officials may be interested in reading about
                (and I'm making no allegations of any kind with this list):
                <ul>
                  <li>
                    The First Amendment to the United States Constitution, which,
                    among other things, grants citizens the right to freely
                    criticize the government without fear of repercussion.
                  </li>
                  <li>
                    The Fourteenth Amendment to the United States Constitution,
                    which, among other things, extends the restrictions applied to
                    the Federal Government to the States.
                  </li>
                  <li>
                    The definition of libel, particularly that government cannot
                    be libeled, and that libel must be malicious to constitute
                    grounds for a lawsuit for damages. Minor errors in reporting
                    are not libel.
                  </li>
                  <li>
                    Rule 32:3.1 of the Iowa Rules of Professional Conduct, which
                    makes it clear that lawyers should inform themselves of the
                    facts of their clients' cases and the applicable law to
                    determine that they can make good faith arguments in support
                    of their clients' positions. Any lawyer familiar with the
                    above points would agree that, for example, a good faith
                    argument could not be made in a case where officials acting as
                    a governmental body attempt to censor a citizen and violate
                    that citizen's First Amendment rights.
                  </li>
                  <li>
                    Reaching out and asking nicely instead of making threats. They
                    may find the recipient surprisingly receptive, not the
                    adversary they apparently thought, and won't end up being
                    <Link to="/free-speech">
                      {' '}
                      sued by the ACLU and turned into a national example of how{' '}
                      <strong>not</strong> to respond to criticism.
                    </Link>
                  </li>
                </ul>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
